import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='container-fluid'>
        <div className='row d-flex justify-content-center pt-3'>
          <div className='col-md-6'>
            <h1 className='h1 text-center'>Privacy Policy</h1>
            <p className="text-center">Date: 5.9.2024</p>
          </div>
        </div>

        <div className='row d-flex justify-content-center pt-1 mb-5'>
          <div className='col-md-10 shadow-xl'>
            <hr className="custom-hr bg-gradient-to-r from-sky-800 to-sky-700" style={{ height: "7px", border: "none" }} />
            <div className='row p-2 text-justify'>
              <p>Welcome to Sankhyana Consultancy Services. This privacy policy outlines how we collect, use, and share your information while you access our website.</p>

              <div className="policy-details">
                <h2>1. Information We Collect:</h2>
                <ul className='list-disc pl-5'>
                  <li>Personal Information such as Name, Email ID.</li>
                  <li>Contact Information such as Phone Number and Address.</li>
                  <li>Courses Related Data such as information about courses taken, quizzes completed, and performance metrics.</li>
                </ul>

                <h2>2. How We Use Your Data:</h2>
                <p>We use your data for the following purposes:</p>
                <ul className='list-disc pl-5'>
                  <li>To provide better services that you access on our website.</li>
                </ul>

                <h2>3. Data Sharing:</h2>
                <p>We may share your data in the following circumstances:</p>
                <ul className='list-disc pl-5'>
                  <li>With service providers and business partners we collaborate with.</li>
                  <li>To comply with legal obligations, protect our rights, or respond to legal requests.</li>
                </ul>

                <h2>4. Your Choices:</h2>
                <p>You can control what information you choose to share with us.</p>

                <h2>5. Third-Party Policies:</h2>
                <p>Our website may contain links to third-party services. We are not responsible for the privacy practices of third-party services.</p>

                <h2>6. Updates to Privacy Policies:</h2>
                <p>Our privacy policies are updated from time to time. Please stay informed by checking our website regularly for updates.</p>

                <h2>7. Contact Us:</h2>
                <p>If you have any questions or concerns, please contact us at <a href="mailto:info@sankhyana.com">info@sankhyana.com</a>.</p>
              </div>

              <div className='row p-4 d-flex justify-content-center'>
                <div className='col-md-9 shadow-xl rounded-lg text-center'>
                  <p className="fw-bold">If you need any further assistance, reach out to us at <a href="mailto:info@sankhyana.com">info@sankhyana.com</a>.</p>
                  <Link to="/contact">
                    <button className='btn border-animation mt-2'>Contact Us</button>
                  </Link>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;