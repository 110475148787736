import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Campion.css';
import NotificationCard from './NotificationCard';

const Campion = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    course: '',
    amount: 0,
    paymentMethod: 'razorpay' // default payment method
  });

  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success', // or 'error' based on the message
  });

  const [paypalLoaded, setPaypalLoaded] = useState(false);

  useEffect(() => {
    const loadPayPalScript = () => {
      const script = document.createElement('script');
      script.src = 'https://www.paypal.com/sdk/js?client-id=AW40SKnYZxtuwE2exSdpSsPmafWIPv370JHmQda7MvVGqM4YdnlYUhXkxjHPsE4-a1dIgFjxMmWEo77H'; // Replace with your client ID
      script.onload = () => setPaypalLoaded(true);
      script.onerror = () => setPaypalLoaded(false);
      document.body.appendChild(script);
    };

    loadPayPalScript();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleClose = () => {
    setNotification({ ...notification, open: false });
  };

  const handlePaymentSuccess = async (details) => {
    try {
      const result = await axios.post('http://localhost:3000/api/payment/success', {
        formData: {
          name: formData.name,
          email: formData.email,
          course: formData.course,
          amount: formData.amount,
        },
        paymentId: details.id,
        paymentMethod: formData.paymentMethod,
      });
      setNotification({
        open: true,
        message: 'Payment successful and data sent to server',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error sending payment data to server:', error);
      setNotification({
        open: true,
        message: 'Error sending payment data to server',
        severity: 'error',
      });
    }
  };

  const handleRazorpayPayment = (e) => {
    e.preventDefault();

    const loadRazorpayScript = () => {
      return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.onload = () => resolve(true);
        script.onerror = () => resolve(false);
        document.body.appendChild(script);
      });
    };

    const initiateRazorpay = async () => {
      const res = await loadRazorpayScript();
      if (!res) {
        alert('Razorpay SDK failed to load. Are you online?');
        return;
      }

      const options = {
        key: 'rzp_live_ITEqmnH4ybxhOS', // Razorpay Key ID
        amount: formData.amount * 100, // Amount in paise
        currency: 'INR',
        name: 'Your LMS Name',
        description: 'Course Registration',
        handler: async (response) => {
          await handlePaymentSuccess({ id: response.razorpay_payment_id });
        },
        prefill: {
          name: formData.name,
          email: formData.email,
        },
        notes: {
          course: formData.course,
        },
        theme: {
          color: '#3399cc',
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    };

    initiateRazorpay();
  };

  const handlePayPalPayment = (e) => {
    e.preventDefault();
    if (paypalLoaded && window.paypal) {
      window.paypal.Buttons({
        createOrder: function (data, actions) {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: formData.amount,
              },
            }],
          });
        },
        onApprove: function (data, actions) {
          return actions.order.capture().then(async function (details) {
            await handlePaymentSuccess(details);
          });
        },
      }).render('#paypal-button-container');
    } else {
      alert('PayPal SDK failed to load. Please try again.');
    }
  };

  return (
    <div className="form-container">
      <h2>Course Registration</h2>
      <form>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            id="name"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            id="email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="course">Course:</label>
          <input
            id="course"
            type="text"
            name="course"
            value={formData.course}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="amount">Amount:</label>
          <input
            id="amount"
            type="number"
            name="amount"
            value={formData.amount}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="paymentMethod">Payment Method:</label>
          <select
            id="paymentMethod"
            name="paymentMethod"
            value={formData.paymentMethod}
            onChange={handleChange}
          >
            <option value="razorpay">Razorpay</option>
            <option value="paypal">PayPal</option>
          </select>
        </div>
        <button
          type="submit"
          onClick={formData.paymentMethod === 'razorpay' ? handleRazorpayPayment : handlePayPalPayment}
        >
          Register & Pay
        </button>
      </form>
      {formData.paymentMethod === 'paypal' && paypalLoaded && window.paypal && (
        <div id="paypal-button-container"></div>
      )}


<NotificationCard
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
        severity={notification.severity}
      />
    </div>
    
  );
};

export default Campion;



